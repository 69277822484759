/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import { ComponentType, Fragment, useEffect, useMemo } from "react";
import type { FC, ReactNode } from "react";
import { useLocation, Link as RouterLink } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";
import { Box, Drawer, Hidden, List, makeStyles } from "@material-ui/core";
import {
	Camera as CameraIcon,
	Monitor as MonitorIcon,
	DollarSign as DollarSignIcon,
	Hash as HashIcon,
	MapPin as MapPinIcon,
	User as UserIcon,
	UserX as UserXIcon,
	UserPlus as UserPlusIcon,
	HelpCircle as HelpIcon,
	FileText as FileTextIcon,
	Settings as SettingsIcon,
	Target as TargetIcon,
	Image as ImageIcon,
	Menu as MenuIcon,
	AlertOctagon as AlertOctagonIcon,
	BarChart as ReportIcon,
	Type as TypeIcon,
	ShoppingBag as ShoppingBagIcon,
	Send as SendIcon,
	ShoppingCart as ShoppingCartIcon,
	Award as AwardIcon,
	CreditCard as CreditCardIcon,
	Layers as LayersIcon,
	Layout as LayoutIcon,
	Truck as TruckIcon,
	Droplet as DropletIcon,
	HardDrive as HardDriveIcon,
	Zap as ZapIcon,
} from "react-feather";
import {
	EmojiTransportationRounded,
	GamepadOutlined,
	BorderColor,
	Group,
	InsertChart,
	LocalShipping,
	LocalOfferOutlined,
	LoyaltyOutlined,
	StorefrontOutlined,
	CasinoOutlined,
	MailOutline,
	RedeemOutlined,
	PlayArrowOutlined,
	AssignmentIndOutlined,
	ReplyAll,
	StarOutline,
	Stars,
	ConfirmationNumber,
	DeveloperBoard,
	LocalCarWash,
	FlightTakeoff,
	ToggleOn,
	FlashOn,
	EmojiPeopleOutlined,
	FilterCenterFocus,
} from "@material-ui/icons";
import {
	mdiLanConnect,
	mdiCarKey,
	mdiClipboardListOutline,
	mdiViewDashboardEdit,
	mdiWebhook,
	mdiRoad,
	mdiLifebuoy,
	mdiRouterWireless,
	mdiBoomGateOutline,
	mdiFormDropdown,
	mdiReceiptOutline,
	mdiGiftOpenOutline,
	mdiAccountMultiple,
	mdiTowTruck,
} from "@mdi/js";
import Logo from "src/components/Logo";
import { REPORT_DASHBOARD_PATH } from "src/views/report-dashboard";
import { Campaign } from "src/icons";
import NavItem from "./NavItem";
import { GamesRoutes } from "src/views/games/games-routes";
import { CodeBucketService } from "src/views/code-buckets/code-bucket.service";
import { PriceGroupService } from "src/views/price-groups/price-group.service";
import { BrandImageRoutes } from "src/views/brand-images/brand-images.routes";
import { AnimationRoutes } from "src/views/animations/animations.routes";
import { EAuthPermission } from "src/contexts/AuthContext";
import { EmployeeRoutes } from "src/views/employee/employee.routes";
import { IntegrationsRoutes } from "src/views/integrations/integrations.routes";
import { PrepaidWashPromotionService } from "src/views/prepaid-wash-promotions/prepaid-wash-promotion.service";
import { LegacyMembersRoutes } from "src/views/user-management/LegacyMembers/legacyMember.routes";
import { EIntegrationKeys } from "amp";
import { SurveyRoutes } from "src/views/survey/survey.routes";
import { ConfigurablePagesRoutes } from "src/views/configurable-pages/configurable-pages.routes";
import { SmsAutomatedReplyRoutes } from "src/views/sms-automated-reply/sms-automated-reply.routes";
import { KioskRoutes } from "src/views/kiosks/kiosk.routes";
import { CustomerJourneyService } from "src/views/customer-journeys/customerJourneyService";
import { LoyaltyRoutes } from "src/views/loyalty/loyalty.routes";
import { EdgeDeviceRoutes } from "src/views/edge-device/edge-device.routes";
import { POPUP_PATH } from "src/views/popups/constants";
import { THEME_ROUTES } from "src/views/themes/theme-routes";
import { lanesRoutes } from "src/views/lanes/lanes.routes";
import { KioskTransactionsRoutes } from "src/views/kiosk-transactions/kiosk-transactions.routes";
import { ControllerRoutes } from "src/views/tunnel-controller/controller.routes";
import { CameraRoutes } from "src/views/cameras/camera.routes";
import { GateRoutes } from "src/views/gates/gates.routes";
import { WashModeRoutes } from "src/views/wash-mode/wash-mode.routes";
import { ScanResultsRoutes } from "src/views/scan-results/scan-results.routes";
import { TransactionsRoutes } from "src/views/transactions/transactions.routes";
import {
	SITE_LEAD_USERS_BASE_ROUTE,
	SITE_LEAD_EMAIL_MIGRATION_ROUTE,
	SITE_LEAD_GUEST_USER_ACTIVITY_ROUTE,
	SITE_LEAD_KIOSK_TRANSACTIONS_ROUTE,
	SITE_LEAD_ALL_TRANSACTIONS_ROUTE,
} from "../../../views/site-lead/UserEditView/site-lead.service";
import { ContactRoutes } from "src/views/contacts/contacts.routes";

interface NavBarProps {
	sections: Section[];
	onMobileClose: () => void;
	openMobile: boolean;
}

interface Item {
	href?: string;
	baseHref?: string;
	icon?: ReactNode;
	info?: ReactNode;
	items?: Item[];
	guard?: ComponentType;
	permissionLevel?: EAuthPermission;
	beta?: boolean;
	hide?: boolean;
	title: string;
}

export interface Section {
	items: Item[];
	subheader: string;
}

export const appSections: Section[] = [
	{
		subheader: "Management",
		items: [
			{
				title: "Dashboard",
				icon: ReportIcon,
				href: REPORT_DASHBOARD_PATH,
			},
			{
				title: "Codes",
				icon: HashIcon,
				href: "/app/management/codes",
			},
			{
				title: "Users & Accounts",
				icon: UserIcon,
				items: [
					{
						title: "Users",
						icon: UserIcon,
						href: "/app/management/users-and-accounts/users",
					},
					{
						title: "Users (Unconfirmed)",
						icon: UserXIcon,
						href: "/app/management/users-and-accounts/unconfirmed-users",
					},
					{
						title: "Employees",
						icon: AssignmentIndOutlined,
						permissionLevel: EAuthPermission.Admin,
						href: EmployeeRoutes.LIST_ROUTE,
						baseHref: EmployeeRoutes.BASE_ROUTE,
					},
					{
						title: "Migrate User Email",
						icon: MailOutline,
						href: "/app/management/users-and-accounts/user-email-migration",
					},
					{
						title: "Legacy Members",
						icon: UserPlusIcon,
						href: LegacyMembersRoutes.LIST_ROUTE,
						baseHref: LegacyMembersRoutes.BASE_ROUTE,
					},
					{
						title: "Fleet Accounts",
						icon: TruckIcon,
						href: "/app/management/users-and-accounts/fleet-accounts",
					},
					{
						title: "Guest User Activity",
						icon: LocalCarWash,
						href: "/app/management/users-and-accounts/guest-user-activity",
					},
					{
						title: "Kiosk Transactions",
						icon: MonitorIcon,
						href: KioskTransactionsRoutes.BASE_ROUTE,
					},
					{
						title: "All Transactions",
						icon: mdiReceiptOutline,
						href: TransactionsRoutes.BASE_ROUTE,
					},
				],
			},
			{
				title: "Marketing",
				icon: Campaign,
				items: [
					{
						title: "Deals",
						icon: DollarSignIcon,
						href: "/app/management/deals",
					},
					{
						title: "Popups",
						icon: AlertOctagonIcon,
						href: "/app/management/popups/list",
						baseHref: POPUP_PATH,
					},
					{
						title: "Messages/Notifications",
						icon: SendIcon,
						href: "/app/management/messages/list",
						baseHref: "/app/management/messages",
					},
					{
						title: "Promo Codes",
						icon: LocalOfferOutlined,
						href: "/app/management/promo-codes",
					},
					{
						title: "Gift Cards",
						icon: CreditCardIcon,
						href: "/app/management/gift-cards",
					},
					{
						title: "Customer Journeys",
						icon: FlightTakeoff,
						href: `${CustomerJourneyService.LIST_ROUTE}`,
					},
					{
						title: "Prepaid Washes",
						icon: RedeemOutlined,
						href: `${PrepaidWashPromotionService.LIST_ROUTE}`,
					},
					{
						title: "Surveys",
						icon: mdiClipboardListOutline,
						href: `${SurveyRoutes.LIST_ROUTE}`,
						baseHref: SurveyRoutes.BASE_ROUTE,
					},
					{
						title: "SMS Automated Replies",
						icon: ReplyAll,
						href: `${SmsAutomatedReplyRoutes.LIST_ROUTE}`,
						baseHref: SmsAutomatedReplyRoutes.BASE_ROUTE,
					},
					{
						title: "Loyalty",
						icon: LoyaltyOutlined,
						href: `${LoyaltyRoutes.BASE_ROUTE}`,
					},
					{
						title: "Contacts",
						icon: mdiAccountMultiple,
						href: `${ContactRoutes.BASE_ROUTE}`,
					},
				],
			},
			{
				title: "Locations & Washes",
				icon: StorefrontOutlined,
				items: [
					{
						title: "Locations",
						icon: MapPinIcon,
						href: "/app/management/locations",
					},
					{
						title: "Wash Types",
						icon: TruckIcon,
						href: "/app/management/wash-types",
					},
					{
						title: "Add Ons",
						icon: ShoppingCartIcon,
						href: "/app/management/add-ons",
					},
					{
						title: "Plans",
						icon: ShoppingBagIcon,
						href: "/app/management/plans",
					},
					{
						title: "Code Groups",
						icon: LayersIcon,
						href: CodeBucketService.PORTAL_URL,
					},
					{
						title: "Price Groups",
						icon: LayoutIcon,
						href: PriceGroupService.PORTAL_URL,
					},
					{
						title: "Wash Modes",
						icon: ToggleOn,
						href: WashModeRoutes.LIST_ROUTE,
						baseHref: WashModeRoutes.BASE_ROUTE,
					},
				],
			},
			{
				title: "Games",
				icon: CasinoOutlined,
				items: [
					{
						title: "Arcade",
						icon: GamepadOutlined,
						href: GamesRoutes.ARCADE_LIST_ROUTE,
					},
					{
						title: "Spinner",
						icon: AwardIcon,
						href: GamesRoutes.SPINNER_LIST_ROUTE,
					},
				],
			},
			{
				title: "Branding",
				icon: TargetIcon,
				items: [
					{
						title: "Themes",
						icon: BorderColor,
						href: THEME_ROUTES.list,
					},
					{
						title: "Brand Images",
						icon: ImageIcon,
						href: BrandImageRoutes.BRAND_IMAGES_LIST_ROUTE,
						baseHref: BrandImageRoutes.BRAND_IMAGES_BASE_ROUTE,
					},
					{
						title: "Animations",
						icon: PlayArrowOutlined,
						href: AnimationRoutes.LIST_ROUTE,
						baseHref: AnimationRoutes.BASE_ROUTE,
					},
				],
			},
			{
				title: "Integrations",
				icon: mdiLanConnect,
				permissionLevel: EAuthPermission.Admin,
				items: [
					{
						title: "AAA",
						icon: mdiTowTruck,
						href: IntegrationsRoutes.getFormRouteByIntegrationKey(
							EIntegrationKeys.AAA,
						),
						baseHref: IntegrationsRoutes.getBaseRouteByIntegrationKey(
							EIntegrationKeys.AAA,
						),
					},
					{
						title: "AMP",
						icon: FlashOn,
						href: IntegrationsRoutes.getListRouteByIntegrationKey(
							EIntegrationKeys.AMP,
						),
						baseHref: IntegrationsRoutes.getBaseRouteByIntegrationKey(
							EIntegrationKeys.AMP,
						),
					},
					{
						title: "DRB",
						icon: ConfirmationNumber,
						href: IntegrationsRoutes.getListRouteByIntegrationKey(
							EIntegrationKeys.DRB,
						),
						baseHref: IntegrationsRoutes.getBaseRouteByIntegrationKey(
							EIntegrationKeys.DRB,
						),
					},
					{
						title: "ICS",
						icon: DeveloperBoard,
						href: IntegrationsRoutes.getListRouteByIntegrationKey(
							EIntegrationKeys.ICS,
						),
						baseHref: IntegrationsRoutes.getBaseRouteByIntegrationKey(
							EIntegrationKeys.ICS,
						),
					},
					{
						title: "iWash",
						icon: mdiCarKey,
						href: IntegrationsRoutes.getFormRouteByIntegrationKey(
							EIntegrationKeys.iWash,
						),
						baseHref: IntegrationsRoutes.getBaseRouteByIntegrationKey(
							EIntegrationKeys.iWash,
						),
					},
					{
						title: "Sonny's",
						icon: DropletIcon,
						href: IntegrationsRoutes.getListRouteByIntegrationKey(
							EIntegrationKeys.Sonnys,
						),
						baseHref: IntegrationsRoutes.getBaseRouteByIntegrationKey(
							EIntegrationKeys.Sonnys,
						),
					},
					{
						title: "KRS",
						icon: StarOutline,
						href: IntegrationsRoutes.getFormRouteByIntegrationKey(
							EIntegrationKeys.KRS,
						),
						baseHref: IntegrationsRoutes.getBaseRouteByIntegrationKey(
							EIntegrationKeys.KRS,
						),
					},
					{
						title: "Webhooks",
						icon: mdiWebhook,
						href: IntegrationsRoutes.getListRouteByIntegrationKey(
							EIntegrationKeys.Webhook,
						),
						baseHref: IntegrationsRoutes.getBaseRouteByIntegrationKey(
							EIntegrationKeys.Webhook,
						),
					},
					{
						title: "World Gift Card",
						icon: mdiGiftOpenOutline,
						href: IntegrationsRoutes.getFormRouteByIntegrationKey(
							EIntegrationKeys.WorldGiftCard,
						),
						baseHref: IntegrationsRoutes.getBaseRouteByIntegrationKey(
							EIntegrationKeys.WorldGiftCard,
						),
					},
					{
						title: "Zendesk",
						icon: mdiLifebuoy,
						href: IntegrationsRoutes.getFormRouteByIntegrationKey(
							EIntegrationKeys.Zendesk,
						),
						baseHref: IntegrationsRoutes.getBaseRouteByIntegrationKey(
							EIntegrationKeys.Zendesk,
						),
					},
				],
			},
			{
				title: "Hardware",
				icon: HardDriveIcon,
				permissionLevel: EAuthPermission.Admin,
				items: [
					{
						title: "Kiosks",
						icon: MonitorIcon,
						href: KioskRoutes.LIST_ROUTE,
						baseHref: KioskRoutes.BASE_ROUTE,
					},
					{
						title: "Lanes",
						icon: mdiRoad,
						href: lanesRoutes.LIST_ROUTE,
						baseHref: lanesRoutes.BASE_ROUTE,
					},
					{
						title: "Edge Devices",
						icon: ZapIcon,
						href: EdgeDeviceRoutes.LIST_ROUTE,
						baseHref: EdgeDeviceRoutes.BASE_ROUTE,
					},
					{
						title: "Gates",
						icon: mdiBoomGateOutline,
						href: GateRoutes.LIST_ROUTE,
						baseHref: GateRoutes.BASE_ROUTE,
					},
					{
						title: "Tunnel Controllers",
						icon: mdiRouterWireless,
						href: ControllerRoutes.LIST_ROUTE,
						baseHref: ControllerRoutes.BASE_ROUTE,
					},
					{
						title: "Cameras",
						icon: CameraIcon,
						href: CameraRoutes.LIST_ROUTE,
						baseHref: CameraRoutes.BASE_ROUTE,
					},
					{
						title: "Scan Results",
						icon: FilterCenterFocus,
						href: ScanResultsRoutes.LIST_ROUTE,
						baseHref: ScanResultsRoutes.BASE_ROUTE,
					},
				],
			},
			{
				title: "App Configurations",
				icon: SettingsIcon,
				href: "/app/management/configurations",
				items: [
					{
						title: "General",
						icon: EmojiTransportationRounded,
						href: "/app/management/configurations/general",
					},
					{
						title: "Attendant Mode",
						icon: EmojiPeopleOutlined,
						href: "/app/management/configurations/attendant-mode",
					},
					{
						title: "Menu Options",
						icon: MenuIcon,
						href: "/app/management/configurations/menu-options",
					},
					{
						title: "Dynamic Dropdown Values",
						icon: mdiFormDropdown,
						href: "/app/management/configurations/dynamic-dropdown-values",
					},
					{
						title: "Custom Pages",
						icon: mdiViewDashboardEdit,
						href: ConfigurablePagesRoutes.LIST_ROUTE,
						permissionLevel: EAuthPermission.Admin,
					},
					{
						title: "Terms & Help Links",
						icon: HelpIcon,
						href: "/app/management/configurations/terms-and-help-links",
					},
					{
						title: "Acknowledgments",
						icon: FileTextIcon,
						href: "/app/management/configurations/acknowledgments",
					},
					{
						title: "Dynamic Text",
						icon: TypeIcon,
						href: "/app/management/configurations/dynamic-text",
					},
					{
						title: "Tax Rates",
						icon: DollarSignIcon,
						href: "/app/management/tax-rates",
					},
					{
						title: "Employee Perks",
						icon: Stars,
						permissionLevel: EAuthPermission.Admin,
						href: "/app/management/employee-perks",
					},
				],
			},
		],
	},
];

export const fleetAdminSections: Section[] = [
	{
		subheader: "Management",
		items: [
			{
				title: "Usage History",
				icon: InsertChart,
				href: "/fleet/usage-history",
			},
			{
				title: "Account Info",
				icon: UserIcon,
				href: "/fleet/account-info",
			},
			{
				title: "Manage Users",
				icon: Group,
				href: "/fleet/manage-users",
			},
			{
				title: "Manage Vehicles",
				icon: LocalShipping,
				href: "/fleet/manage-vehicles",
			},
		],
	},
];

export const siteLeadSections: Section[] = [
	{
		subheader: "Site Lead",
		items: [
			{
				title: "Users & Accounts",
				icon: UserIcon,
				items: [
					{
						title: "Users",
						icon: UserIcon,
						href: SITE_LEAD_USERS_BASE_ROUTE,
					},
					{
						title: "Migrate User Email",
						icon: MailOutline,
						href: SITE_LEAD_EMAIL_MIGRATION_ROUTE,
					},
					{
						title: "Guest User Activity",
						icon: LocalCarWash,
						href: SITE_LEAD_GUEST_USER_ACTIVITY_ROUTE,
					},
					{
						title: "Kiosk Transactions",
						icon: MonitorIcon,
						href: SITE_LEAD_KIOSK_TRANSACTIONS_ROUTE,
					},
					{
						title: "All Transactions",
						icon: mdiReceiptOutline,
						href: SITE_LEAD_ALL_TRANSACTIONS_ROUTE,
					},
				],
			},
		],
	},
];

function renderNavItems({
	items,
	pathname,
	depth = 0,
}: {
	items: Item[];
	pathname: string;
	depth?: number;
}) {
	return (
		<List disablePadding>
			{items.reduce(
				(acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
				[],
			)}
		</List>
	);
}

function reduceChildRoutes({
	acc,
	pathname: currentRoute,
	item,
	depth,
}: {
	acc: any[];
	pathname: string;
	item: Item;
	depth: number;
}) {
	const key = item.title + depth;
	const Guard = item.guard ?? Fragment;

	if (item.items) {
		// get all routes
		const routeNames: (string | undefined)[] = getAllChildRoutes(item);
		routeNames.push(item.href);
		// check if item is in current route and set open
		const open = isItemInCurrentRoute(currentRoute, routeNames);
		acc.push(
			<Guard key={key}>
				<NavItem
					depth={depth}
					icon={item.icon}
					info={item.info}
					open={Boolean(open)}
					title={item.title}
					beta={item.beta}
					permissionLevel={item.permissionLevel}
					pathName={currentRoute}
				>
					{renderNavItems({
						depth: depth + 1,
						pathname: currentRoute,
						items: item.items,
					})}
				</NavItem>
			</Guard>,
		);
	} else {
		const active = item.href
			? isItemInCurrentRoute(currentRoute, [item.baseHref ?? item.href])
			: false;
		acc.push(
			<Guard key={key}>
				<NavItem
					depth={depth}
					href={item.href}
					icon={item.icon}
					info={item.info}
					beta={item.beta}
					permissionLevel={item.permissionLevel}
					title={item.title}
					active={active}
				/>
			</Guard>,
		);
	}

	return acc;
}

const useStyles = makeStyles(() => ({
	mobileDrawer: {
		width: 256,
	},
	desktopDrawer: {
		width: 256,
		top: 64,
		height: "calc(100% - 64px)",
	},
	avatar: {
		cursor: "pointer",
		width: 64,
		height: 64,
	},
}));

const NavBar: FC<NavBarProps> = ({ sections, onMobileClose, openMobile }) => {
	const classes = useStyles();
	const location = useLocation();

	useEffect(() => {
		if (openMobile && onMobileClose) {
			onMobileClose();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location.pathname]);

	const content = useMemo(
		() => (
			<Box height="100%" display="flex" flexDirection="column">
				<PerfectScrollbar options={{ suppressScrollX: true }}>
					<Hidden lgUp>
						<Box p={2} display="flex" justifyContent="center">
							<RouterLink to="/">
								<Logo />
							</RouterLink>
						</Box>
					</Hidden>
					<Box p={2}>
						{sections.map((section) => (
							<List key={section.subheader}>
								{renderNavItems({
									items: section.items,
									pathname: location.pathname,
								})}
							</List>
						))}
					</Box>
				</PerfectScrollbar>
			</Box>
		),
		[location.pathname, sections],
	);

	return (
		<>
			<Hidden lgUp>
				<Drawer
					anchor="left"
					classes={{ paper: classes.mobileDrawer }}
					onClose={onMobileClose}
					open={openMobile}
					variant="temporary"
				>
					{content}
				</Drawer>
			</Hidden>
			<Hidden mdDown>
				<Drawer
					anchor="left"
					classes={{ paper: classes.desktopDrawer }}
					open
					variant="persistent"
				>
					{content}
				</Drawer>
			</Hidden>
		</>
	);
};

NavBar.propTypes = {
	onMobileClose: PropTypes.func.isRequired,
	openMobile: PropTypes.bool.isRequired,
};

export default NavBar;

function isItemInCurrentRoute(
	currentRoute: string,
	itemRoutes: (string | undefined)[],
) {
	return itemRoutes?.some((itemRoute) => {
		return itemRoute ? currentRoute.startsWith(itemRoute) : false;
	});
}

const getAllChildRoutes = (
	navBarItem: Item,
	hrefs: (string | undefined)[] = [],
): (string | undefined)[] => {
	// Collect all paths from current item children
	navBarItem?.items?.forEach((childNavBarItem) => {
		if (childNavBarItem.href || childNavBarItem.baseHref) {
			hrefs.push(childNavBarItem.baseHref ?? childNavBarItem.href);
		}

		// If the current item child has nested items, call the function recursively
		if (childNavBarItem?.items) {
			childNavBarItem?.items?.forEach((item) => getAllChildRoutes(item, hrefs));
		}
		return hrefs;
	});

	return hrefs;
};
